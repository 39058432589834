import React, { useState, useEffect, useCallback } from "react"
import * as styles from "./list.module.css"
import Layout from "components/common/layout.js"
import Pagenation from "components/tools/pagination.js"
import api from "apis"
import { getVideoListAsync } from "store/modules/postReducer.js"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { getDateYMD, textEdit, titleEdit, getThumb, getEpisode } from "utils"
import peachIcons from "images/peachIcons.png"
import { navigate } from "gatsby-link"
import OrderMenu from "components/tools/orderMenu"
import queryString from "query-string"
const List = props => {
  const dispatch = useDispatch()
  const getVideoList = useCallback(
    (currentPage, pagePostSize, order) =>
      dispatch(getVideoListAsync(currentPage, pagePostSize, order)),
    [dispatch]
  )

  const [order, setOrder] = useState(1)
  const { page } = queryString.parse(props.location.search)

  const [currentPage, setCurrentPage] = useState(page ? Number(page) : 1)
  const pagePostSize = 15

  const { videoList, videoTotal } = useSelector(
    ({ postReducer: { video } }) => video
  )

  useEffect(() => {
    ;(async () => {
      await getVideoList(currentPage, pagePostSize, order)
    })()
  }, [order, currentPage])

  console.log(videoList, videoTotal)
  return (
    <Layout>
      <div className={styles.con}>
        <div className={styles.title} style={{ marginTop: "60px" }}>
          영상으로 한눈에 알아보는 정보
        </div>
        {/* <div className={styles.menu}>
          {Array.from(orderTable).map(([key, value]) => (
            <div
              key={key}
              data-order={key}
              className={order === key ? styles.on : ""}
              onClick={({
                target: {
                  dataset: { order },
                },
              }) => setOrder(Number(order))}
            >
              {value}
            </div>
          ))}
        </div> */}
        <div style={{ paddingLeft: "33px" }}>
          <OrderMenu
            order={order}
            setOrder={setOrder}
            setCurrentPage={setCurrentPage}
          />
        </div>
        <div className={styles.main}>
          {videoList.map((post, i) => {
            return (
              <div key={post.video_no}>
                <div
                  onClick={() => {
                    navigate(
                      `/visible/media/detail?video_no=${
                        post.video_no
                      }&currentPage=${currentPage}&pagePostSize=${pagePostSize}&idx=${
                        i + 1
                      }`
                    )
                  }}
                  className={styles.video}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <img
                    src={getThumb(post.thumb_title_uuid)}
                    className={styles.videoImg}
                  />
                  <div
                    className={styles.text}
                    style={{
                      gap: "17px",
                    }}
                  >
                    <img
                      src={peachIcons}
                      style={{
                        width: "40px",
                        height: "40px",
                        marginTop: "5px",
                      }}
                    />
                    <div>
                      <div>{post.title}</div>
                      <div>{post.introText}</div>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
        <div style={{ margin: "100px" }}>
          <Pagenation
            total={videoTotal}
            pagePostSize={pagePostSize}
            pageSize={10}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </div>
      </div>
    </Layout>
  )
}

export default List
